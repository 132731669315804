<template>
    <div class="auth-login">
        <div class="login-box">
            <a class="logo">
                <img draggable="false" src="../../assets/img/logo-new.png" />
                <span>浙江永达</span>
            </a>
            <el-form :model="loginForm" label-position="left" label-width="60px">
                <el-form-item label="用户名">
                    <el-input v-model="loginForm.name" placeholder="请输入您的用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="loginForm.password" placeholder="请输入您的密码" type="password"></el-input>
                </el-form-item>
            </el-form>
            <el-button class="login-button" type="primary" @click="login">登录</el-button>
            <!--<a class="reset-button">忘记密码?点击找回密码</a>-->
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { AUTH_LOGIN } from "@/api/auth";
import { ElMessage } from "element-plus";
import usePublic from "../../hooks/usePublic";

let { $route, $router, $store } = usePublic();

let loginForm = ref({
    name: "",
    password: ""
});

let login = () => {
    AUTH_LOGIN({ ...loginForm.value }).then((res) => {
        if (res.data.code === 200) {
            $store.commit("setToken", res.data.access_token);
            $store.dispatch("actionUserData");
            ElMessage.success({
                message: "登录成功，即将为您跳转！",
                onClose: () => {
                    $router.push({ path: "/" });
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.auth-login {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/img/background/login-bg.png") no-repeat center;
    background-size: cover;

    .login-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        padding: 20px 30px 50px 30px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(#000, 0.1);

        .logo {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            justify-content: center;

            img {
                width: 88px;
            }
            span {
                margin-left: 10px;
                font-size: 30px;
                font-weight: bold;
                color: #d92821;
            }
        }

        .login-button {
            width: 100%;
        }

        .reset-button {
            display: block;
            margin-top: 20px;
            text-align: center;
            font-size: 14px;
            color: #333;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                color: #409eff;
            }
        }
    }
}
</style>
